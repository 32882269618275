import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


 const useStyles = makeStyles((theme: Theme) =>
   createStyles({
     root: {
       position: 'relative'
     },
     textContainer: {
      width: '100%',
      textAlign: 'center',
      top: 35,
    },
    text: {
      fontSize: theme.typography.pxToRem(50),
      fontWeight: 700,
      margin: '50px 15px 0',
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.pxToRem(25)
      }
    },
    subText: {
      fontSize: theme.typography.pxToRem(20),
      marginTop: 12,
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.pxToRem(14),
        margin: '12px 30px'
      }
    },
    
   }),
 );

export const Header = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.textContainer}>
        <Typography component="h1" className={classes.text}>
          Blogs
        </Typography>
        <Typography className={classes.subText}>
          Find out how diverse a company is in seconds.
          <br/>
          More companies added weekly.
        </Typography>
      </Box>
     
    </Box>
  )
}
