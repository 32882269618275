import { Grid } from "@material-ui/core";
import React from "react";
import BlogCard from "./Card";
import { Header } from "./Header";

export const Blogs = ({blogs}) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Header />
            <Grid container alignItems="center" style={{ maxWidth: "1500px" }}>
                {blogs?.map((blog, index) => (
                    <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
                        <BlogCard blog={blog} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};