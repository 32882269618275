import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    border: '1px solid #e6e6e6',
    margin: '40px',
  },
  media: {
    width : '100%',
    height: 160,
  },
});

export default function BlogCard({blog}) {
  const classes = useStyles();
  return (
    <div>
    <Card className={classes.root}>
      <CardActionArea>
        <GatsbyImage
        className={classes.media}
        objectFit="cover"
          alt="Rebolt diversity image"
          image={blog.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {blog.frontmatter.title}
          </Typography>
          <Typography variant="body1" color="textPrimary" component="p" >{blog.excerpt}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="medium" color="primary" variant='contained' onClick={()=> navigate(`/blog/${blog.frontmatter.slug}`)} >
          Read More
        </Button>
      </CardActions>
    </Card>
   </div>
  );
}
