import React from 'react'
import { graphql } from 'gatsby'
import { Blogs } from '../components/blog'
import { Layout } from '../components/common/layout'
import { Seo } from '../components/common/seo'

const Blog = ({ data }) => {
  const blogs = data.allMarkdownRemark.nodes;
  return (
    <Layout>
      <Seo title='Blog' />
      <Blogs blogs={blogs} />
    </Layout>
  )
}

export default Blog

export const query = graphql`
  {
    allMarkdownRemark {
      nodes {
        frontmatter {
          title
          thumbnail {
            childImageSharp {
              gatsbyImageData
            }
          }
          slug
          layout
        }
        excerpt(truncate: true, pruneLength: 100)
      }
    }
  }
`